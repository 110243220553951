.app-header{
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 15%) 0px 0px 4px 4px;
}

.app-header-row {
  height: '100%';
}

.bg-light{
  background-color: #fff;
}

.site-content {
background-color: #fff;

}

.boundry{
  border: #fff;
}

.large-box{
  width: 200px;
  height: 200px;
}

.ant-layout {
  background: #fff;
}

@import '~antd/dist/antd.css';

.App {
    text-align: left;
  } 
  .center{
    background-color: #04ff8a;
    height: 400px;
    width: auto;
    text-align: center;
  
  }

  .box {
  transition: box-shadow .3s;
  border: 1px solid rgb(204, 204, 204);
  background: #fff;
  float: left;
  
}
.box:hover {
  box-shadow: 0 0 11px rgba(33,33,33,.2); 
}

